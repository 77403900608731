import React from 'react';
import {
  ContextBar,
  ContextLink,
  ContextItem,
} from 'src/components/layout/contextBar';
import Layout, {ContentWrapper} from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import styles from './index.module.scss';

export default (props) => {
  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <SEO title="Support" />
        <ContextBar>
          <ContextLink
            divider
            text="Account"
            to="/account"
          />
          <ContextItem
            text="Support"
          />
        </ContextBar>
        <ContentWrapper>
          <Card className={styles.supportMessage}>
            <div>
              Questions about your account?<br />
              Just want to say hello?<br /><br />
            </div>
            <div>
              Send an email to&nbsp;
              <a href="mailto:hackerpaperhelp@gmail.com" target="_blank">
                hackerpaperhelp@gmail.com
              </a>
              &nbsp;or tweet at&nbsp;
              <a href="https://twitter.com/troy_carlson" target="_blank">
                @troy_carlson
              </a>
              &nbsp;and I'll get back to you as soon as I can.
            </div>
          </Card>
        </ContentWrapper>
      </div>
    </Layout>
  );
};
